import React, { useState } from 'react';
import { auth } from '../firebase';
import {
	signInWithPopup,
	GoogleAuthProvider,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
} from 'firebase/auth';

function LandingPage() {
	const [showLoginForm, setShowLoginForm] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [isRegistering, setIsRegistering] = useState(false);
	const [error, setError] = useState('');

	const handleGoogleLogin = async () => {
		try {
			const provider = new GoogleAuthProvider();
			await signInWithPopup(auth, provider);
		} catch (error) {
			console.error('Error signing in with Google:', error);
			setError(error.message);
		}
	};

	const handleEmailLogin = async (e) => {
		e.preventDefault();
		try {
			if (isRegistering) {
				if (password !== confirmPassword) {
					setError("Passwords don't match");
					return;
				}
				await createUserWithEmailAndPassword(auth, email, password);
			} else {
				await signInWithEmailAndPassword(auth, email, password);
			}
			setEmail('');
			setPassword('');
			setConfirmPassword('');
			setError('');
		} catch (error) {
			console.error('Error with email auth:', error);
			setError(error.message);
		}
	};

	const handleToggleMode = () => {
		setIsRegistering(!isRegistering);
		setError('');
		setEmail('');
		setPassword('');
		setConfirmPassword('');
	};

	return (
		<div className='landing-page'>
			<div className='landing-content'>
				<h1>BRUTUS</h1>
				<h2>THE TASK MANAGER THAT DOESN'T CARE ABOUT YOUR FEELINGS</h2>

				<div className='feature-section'>
					<div className='feature'>
						<h3>NO MULTITASKING</h3>
						<p>One task at a time. No exceptions. No excuses.</p>
					</div>
					<div className='feature'>
						<h3>NO TASK SWITCHING</h3>
						<p>
							Finish what you started or stare at it until you do.
						</p>
					</div>
					<div className='feature'>
						<h3>NO BULLSHIT</h3>
						<p>Just pure, brutal productivity.</p>
					</div>
				</div>

				<div className='how-it-works'>
					<h3>HOW IT WORKS</h3>
					<ol>
						<li>
							<span className='step'>1. BRAIN DUMP</span>
							<p>Dump all your tasks. Don't think. Just dump.</p>
						</li>
						<li>
							<span className='step'>2. ASSIGN</span>
							<p>
								BRUTUS puts 3 tasks in NOW, the rest in LATER.
							</p>
						</li>
						<li>
							<span className='step'>3. DO IT</span>
							<p>
								Complete the first task before touching anything
								else.
							</p>
						</li>
					</ol>
				</div>

				<div className='cta-section'>
					<h3>READY TO GET BRUTAL?</h3>
					<button
						onClick={() => setShowLoginForm(true)}
						className='cta-button'
					>
						START BEING BRUTAL
					</button>
				</div>
			</div>

			{showLoginForm && (
				<div className='login-popup'>
					<div className='login-form'>
						<button
							className='close-btn'
							onClick={() => setShowLoginForm(false)}
						>
							×
						</button>
						<h2>{isRegistering ? 'Register' : 'Login'}</h2>
						{error && <div className='error-message'>{error}</div>}
						<form onSubmit={handleEmailLogin}>
							<input
								type='email'
								placeholder='Email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								required
							/>
							<input
								type='password'
								placeholder='Password'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								required
							/>
							{isRegistering && (
								<input
									type='password'
									placeholder='Confirm Password'
									value={confirmPassword}
									onChange={(e) =>
										setConfirmPassword(e.target.value)
									}
									required
								/>
							)}
							<button type='submit'>
								{isRegistering ? 'Register' : 'Login'}
							</button>
						</form>
						<button
							className='google-login-btn'
							onClick={handleGoogleLogin}
						>
							Login with Google
						</button>
						<button
							className='toggle-auth-mode'
							onClick={handleToggleMode}
						>
							{isRegistering
								? 'Already have an account? Login'
								: 'Need an account? Register'}
						</button>
					</div>
				</div>
			)}
		</div>
	);
}

export default LandingPage;
