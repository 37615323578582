import React, { useState, useEffect, useCallback } from 'react';
import { auth } from './firebase';
import Header from './components/Header';
import {
	fetchProjects,
	fetchTodos,
	addTodo,
	updateTodoTab,
	updateTodoCompletion,
	fetchTaskCompletionCounts,
	deleteTodo,
	addProject, // Import addProject here
} from './utils/api';
import TodoForm from './components/TodoForm';
import LandingPage from './components/LandingPage';
import './App.css';

function App() {
	const [user, setUser] = useState(null);
	const [projects, setProjects] = useState([]);
	const [currentProject, setCurrentProject] = useState(null);
	const [todos, setTodos] = useState([]);
	const [activeTab, setActiveTab] = useState('brainDump');
	const [popupMessage, setPopupMessage] = useState(null);
	const [taskCounts, setTaskCounts] = useState({
		tasksCompletedToday: 0,
		tasksCompletedThisWeek: 0,
		tasksCompletedEver: 0,
	});
	const [animatingTodoId, setAnimatingTodoId] = useState(null);
	const [animatingTodos, setAnimatingTodos] = useState(new Set());
	const [projectName, setProjectName] = useState(''); // Add this line
	const [brainDumpTodos, setBrainDumpTodos] = useState([]); // Add this line
	const [loading, setLoading] = useState(true);
	const [showLoginForm, setShowLoginForm] = useState(false);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			setUser(user);
			if (user) {
				loadProjects(user.uid);
			} else {
				setProjects([]);
				setCurrentProject(null);
				setTodos([]);
			}
			setLoading(false);
		});

		return () => unsubscribe();
	}, []);

	useEffect(() => {
		if (user && currentProject) {
			loadTodos(user.uid, currentProject.id);
		}
	}, [user, currentProject]);

	useEffect(() => {
		if (user) {
			loadTaskCounts();
		}
	}, [user]);

	const loadProjects = async (userId) => {
		try {
			const fetchedProjects = await fetchProjects(userId);
			setProjects(fetchedProjects);
			if (fetchedProjects.length > 0) {
				setCurrentProject(fetchedProjects[0]);
			}
		} catch (error) {
			console.error('Error loading projects:', error);
		}
	};

	const loadTodos = async (userId, projectId) => {
		try {
			const fetchedTodos = await fetchTodos(userId, projectId);
			setTodos(fetchedTodos);
		} catch (error) {
			console.error('Error loading todos:', error);
		}
	};

	const loadTaskCounts = async () => {
		if (user) {
			try {
				const counts = await fetchTaskCompletionCounts(user.uid);
				setTaskCounts(counts);
			} catch (error) {
				console.error('Error loading task counts:', error);
			}
		}
	};

	const handleProjectAdded = (newProject) => {
		setProjects([...projects, newProject]);
		setCurrentProject(newProject);
	};

	const handleProjectSelected = (project) => {
		setCurrentProject(project);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (projectName.trim()) {
			try {
				const newProject = await addProject(user.uid, projectName);
				handleProjectAdded(newProject);
				setProjectName('');
			} catch (error) {
				console.error('Error adding project:', error);
			}
		}
	};

	const addNewTodo = async (text) => {
		if (!user || !currentProject) return;

		try {
			if (activeTab === 'brainDump') {
				// For brain dump, just add to the brain dump list
				const newTodo = await addTodo(
					user.uid,
					currentProject.id,
					text,
					'brainDump'
				);
				setBrainDumpTodos((prev) => [...prev, newTodo]);
			} else {
				// Original logic for other tabs
				let tab = 'later';
				const nowTodos = todos.filter(
					(todo) => todo.tab === 'now' && !todo.isCompleted
				);

				if (nowTodos.length < 3) {
					tab = 'now';
				}

				const newTodo = await addTodo(
					user.uid,
					currentProject.id,
					text,
					tab
				);
				let updatedTodos = [...todos, newTodo];

				if (tab === 'later' && nowTodos.length < 3) {
					newTodo.tab = 'now';
					await updateTodoTab(newTodo.id, 'now');
					updatedTodos = updatedTodos.map((todo) =>
						todo.id === newTodo.id ? { ...todo, tab: 'now' } : todo
					);
				}

				setTodos(updatedTodos);
			}
		} catch (error) {
			console.error('Error adding todo:', error);
		}
	};

	const handleAssignTasks = async () => {
		if (brainDumpTodos.length === 0) return;

		try {
			// Get current NOW todos
			const currentNowTodos = todos.filter(
				(todo) => todo.tab === 'now' && !todo.isCompleted
			);

			let updatedTodos = [...todos];
			const tasksToAssign = [...brainDumpTodos];

			// Calculate how many slots are available in NOW
			const availableNowSlots = 3 - currentNowTodos.length;

			// Take first N tasks for NOW (where N is availableNowSlots)
			const tasksForNow = tasksToAssign.splice(0, availableNowSlots);

			// Update NOW tasks
			for (const todo of tasksForNow) {
				await updateTodoTab(todo.id, 'now');
				todo.tab = 'now';
				updatedTodos.push(todo);
			}

			// Move remaining tasks to LATER
			for (const todo of tasksToAssign) {
				await updateTodoTab(todo.id, 'later');
				todo.tab = 'later';
				updatedTodos.push(todo);
			}

			setTodos(updatedTodos);
			setBrainDumpTodos([]); // Clear brain dump
		} catch (error) {
			console.error('Error assigning tasks:', error);
		}
	};

	const toggleTodoCompletion = useCallback(
		async (todoId, isCompleted) => {
			// Get the completed todo's information at the start of the function
			const completedTodo = todos.find((todo) => todo.id === todoId);

			if (isCompleted) {
				setAnimatingTodos((prev) => new Set(prev).add(todoId));

				setTimeout(() => {
					setAnimatingTodos((prev) => {
						const newSet = new Set(prev);
						newSet.delete(todoId);
						return newSet;
					});

					setTodos((prevTodos) => {
						const updatedTodos = prevTodos.map((todo) =>
							todo.id === todoId
								? { ...todo, tab: 'done', isCompleted: true }
								: todo
						);

						// If a NOW todo was completed, move the next LATER todo to NOW
						if (completedTodo.tab === 'now') {
							const nowTodos = updatedTodos.filter(
								(todo) =>
									todo.tab === 'now' && !todo.isCompleted
							);

							// If we have less than 3 active NOW todos
							if (nowTodos.length < 3) {
								// Get all LATER todos sorted by creation time
								const laterTodos = updatedTodos
									.filter(
										(todo) =>
											todo.tab === 'later' &&
											!todo.isCompleted
									)
									.sort((a, b) => a.createdAt - b.createdAt);

								// If there are LATER todos available
								if (laterTodos.length > 0) {
									// Move the next LATER todo to NOW
									const nextTodo = laterTodos[0];
									return updatedTodos.map((todo) =>
										todo.id === nextTodo.id
											? { ...todo, tab: 'now' }
											: todo
									);
								}
							}
						}

						return updatedTodos;
					});
				}, 500);
			}

			// Immediately update the local state
			setTodos((prevTodos) =>
				prevTodos.map((todo) =>
					todo.id === todoId ? { ...todo, isCompleted } : todo
				)
			);

			// Update task counts
			setTaskCounts((prevCounts) => ({
				tasksCompletedToday:
					prevCounts.tasksCompletedToday + (isCompleted ? 1 : -1),
				tasksCompletedThisWeek:
					prevCounts.tasksCompletedThisWeek + (isCompleted ? 1 : -1),
				tasksCompletedEver:
					prevCounts.tasksCompletedEver + (isCompleted ? 1 : -1),
			}));

			try {
				await updateTodoCompletion(user.uid, todoId, isCompleted);

				if (isCompleted && completedTodo.tab === 'now') {
					// Update the tab in the database for the next todo
					const laterTodos = todos
						.filter(
							(todo) => todo.tab === 'later' && !todo.isCompleted
						)
						.sort((a, b) => a.createdAt - b.createdAt);

					if (laterTodos.length > 0) {
						const nextTodo = laterTodos[0];
						await updateTodoTab(nextTodo.id, 'now');
					}
				}
			} catch (error) {
				console.error('Error updating todo completion:', error);
				// Revert the local state changes if the server update fails
				setTodos((prevTodos) =>
					prevTodos.map((todo) =>
						todo.id === todoId
							? { ...todo, isCompleted: !isCompleted }
							: todo
					)
				);
				setTaskCounts((prevCounts) => ({
					tasksCompletedToday:
						prevCounts.tasksCompletedToday + (isCompleted ? -1 : 1),
					tasksCompletedThisWeek:
						prevCounts.tasksCompletedThisWeek +
						(isCompleted ? -1 : 1),
					tasksCompletedEver:
						prevCounts.tasksCompletedEver + (isCompleted ? -1 : 1),
				}));
			}
		},
		[todos, user]
	);

	const handleUncheckableTaskClick = (firstNowTask) => {
		setPopupMessage(
			`Nope! You need to complete "${firstNowTask}" before you move on to the next thing.`
		);
		setTimeout(() => setPopupMessage(null), 3000); // Hide popup after 3 seconds
	};

	const handleDeleteTodo = async (todoId) => {
		try {
			await deleteTodo(todoId);
			setTodos((prevTodos) =>
				prevTodos.filter((todo) => todo.id !== todoId)
			);
		} catch (error) {
			console.error('Error deleting todo:', error);
		}
	};

	const renderTabContent = useCallback(() => {
		if (activeTab === 'brainDump') {
			return (
				<>
					<TodoForm addTodo={addNewTodo} />
					<ul className='todo-list'>
						{brainDumpTodos.map((todo) => (
							<li
								key={todo.id}
								className='todo-item'
							>
								<div className='todo-content'>
									<span>{todo.text}</span>
								</div>
								<button
									className='delete-btn'
									onClick={() => handleDeleteTodo(todo.id)}
								>
									<span className='delete-icon'>x</span>
								</button>
							</li>
						))}
					</ul>
					{brainDumpTodos.length > 0 && (
						<button
							onClick={handleAssignTasks}
							className='assign-tasks-btn'
						>
							Assign Tasks
						</button>
					)}
				</>
			);
		}

		const filteredTodos = todos.filter((todo) => {
			if (activeTab === 'done') {
				return todo.tab === 'done';
			} else {
				return todo.tab === activeTab && !todo.isCompleted;
			}
		});

		const sortedTodos =
			activeTab === 'done'
				? filteredTodos
				: [...filteredTodos].sort((a, b) => a.createdAt - b.createdAt);

		const firstNowTask =
			todos.find((todo) => todo.tab === 'now' && !todo.isCompleted)
				?.text || 'the first task';

		return (
			<ul className='todo-list'>
				{sortedTodos.map((todo, index) => (
					<li
						key={todo.id}
						className={`todo-item ${
							todo.isCompleted ? 'completed' : ''
						} ${animatingTodos.has(todo.id) ? 'animating' : ''}`}
					>
						<div className='todo-content'>
							<input
								type='checkbox'
								checked={todo.isCompleted}
								onChange={() => {
									if (
										activeTab === 'later' ||
										(activeTab === 'now' && index !== 0)
									) {
										handleUncheckableTaskClick(
											firstNowTask
										);
									} else {
										toggleTodoCompletion(
											todo.id,
											!todo.isCompleted
										);
									}
								}}
								className={
									activeTab === 'later' ||
									(activeTab === 'now' && index !== 0)
										? 'disabled-checkbox'
										: ''
								}
							/>
							<span>{todo.text}</span>
						</div>
						<button
							className='delete-btn'
							onClick={() => handleDeleteTodo(todo.id)}
						>
							<span className='delete-icon'>x</span>
						</button>
					</li>
				))}
			</ul>
		);
	}, [todos, activeTab, animatingTodos, brainDumpTodos]); // Add brainDumpTodos to dependencies

	if (loading) {
		return <div className='loading'>Loading...</div>;
	}

	if (!user) {
		return <LandingPage onLoginClick={() => setShowLoginForm(true)} />;
	}

	return (
		<div className='App'>
			<Header
				user={user}
				projects={projects}
				taskCounts={taskCounts}
				showLoginForm={showLoginForm}
				setShowLoginForm={setShowLoginForm}
			/>
			<main>
				<>
					<div className='project-title'>
						<h2>
							{currentProject
								? currentProject.name
								: 'No Project Selected'}
						</h2>
					</div>
					<div className='project-header'>
						<form
							onSubmit={handleSubmit}
							className='add-project-form'
						>
							<input
								type='text'
								value={projectName}
								onChange={(e) => setProjectName(e.target.value)}
								placeholder='Enter project name'
								required
							/>
							<button type='submit'>Add Project</button>
						</form>
						<select
							value={currentProject ? currentProject.id : ''}
							onChange={(e) =>
								handleProjectSelected(
									projects.find(
										(p) => p.id === e.target.value
									)
								)
							}
							className='project-select'
						>
							<option value=''>Select a project</option>
							{projects.map((project) => (
								<option
									key={project.id}
									value={project.id}
								>
									{project.name}
								</option>
							))}
						</select>
					</div>
				</>
				<div className='tabs'>
					<button
						className={`tab ${
							activeTab === 'brainDump' ? 'active' : ''
						}`}
						onClick={() => setActiveTab('brainDump')}
					>
						Brain Dump
					</button>
					<button
						className={`tab ${activeTab === 'now' ? 'active' : ''}`}
						onClick={() => setActiveTab('now')}
					>
						Now
					</button>
					<button
						className={`tab ${
							activeTab === 'later' ? 'active' : ''
						}`}
						onClick={() => setActiveTab('later')}
					>
						Later
					</button>
					<button
						className={`tab ${
							activeTab === 'done' ? 'active' : ''
						}`}
						onClick={() => setActiveTab('done')}
					>
						Done
					</button>
				</div>
				{user ? (
					currentProject ? (
						renderTabContent()
					) : (
						<p>
							Please select or create a project to start adding
							tasks.
						</p>
					)
				) : (
					<p>Please sign in to view and manage your tasks.</p>
				)}
			</main>
			{popupMessage && (
				<div className='popup-message'>
					<p>{popupMessage}</p>
				</div>
			)}
		</div>
	);
}

export default App;
