import axios from 'axios';
import { db } from '../firebase';
import {
	collection,
	addDoc,
	getDocs,
	query,
	where,
	updateDoc,
	doc,
	increment,
	getDoc,
	setDoc,
	deleteDoc,
} from 'firebase/firestore';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function fetchAIChatHistory(/* timePeriod */) {
	try {
		// Commenting out the timePeriod parameter in the API call
		// const response = await axios.get(`${API_BASE_URL}/ai-chat-history?timePeriod=${timePeriod}`);
		const response = await axios.get(`${API_BASE_URL}/ai-chat-history`);
		return response.data;
	} catch (error) {
		console.error('Error fetching AI chat history:', error);
		throw error;
	}
}

export async function addProject(userId, projectName) {
	console.log('Adding project:', userId, projectName);
	try {
		const projectRef = await addDoc(collection(db, 'projects'), {
			name: projectName,
			userId: userId,
			createdAt: new Date(),
		});
		console.log('Project added successfully:', projectRef.id);
		return { id: projectRef.id, name: projectName };
	} catch (error) {
		console.error('Error adding project:', error);
		console.error('Error details:', error.code, error.message);
		throw error;
	}
}

export async function fetchProjects(userId) {
	try {
		const projectsQuery = query(
			collection(db, 'projects'),
			where('userId', '==', userId)
		);
		const querySnapshot = await getDocs(projectsQuery);
		return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
	} catch (error) {
		console.error('Error fetching projects:', error);
		throw error;
	}
}

export async function addTodo(userId, projectId, text, tab) {
	try {
		const todoRef = await addDoc(collection(db, 'todos'), {
			userId,
			projectId,
			text,
			tab,
			isCompleted: false,
			createdAt: new Date(),
		});
		return { id: todoRef.id, text, tab, isCompleted: false };
	} catch (error) {
		console.error('Error adding todo:', error);
		throw error;
	}
}

export async function fetchTodos(userId, projectId) {
	try {
		const todosQuery = query(
			collection(db, 'todos'),
			where('userId', '==', userId),
			where('projectId', '==', projectId)
		);
		const querySnapshot = await getDocs(todosQuery);
		return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
	} catch (error) {
		console.error('Error fetching todos:', error);
		throw error;
	}
}

export async function updateTodoTab(todoId, newTab) {
	try {
		await updateDoc(doc(db, 'todos', todoId), { tab: newTab });
	} catch (error) {
		console.error('Error updating todo tab:', error);
		throw error;
	}
}

export async function updateTodoCompletion(userId, todoId, isCompleted) {
	try {
		await updateDoc(doc(db, 'todos', todoId), { isCompleted });

		// Update task completion counts
		const userStatsRef = doc(db, 'userStats', userId);
		const userStatsDoc = await getDoc(userStatsRef);

		if (!userStatsDoc.exists()) {
			await setDoc(userStatsRef, {
				tasksCompletedToday: 0,
				tasksCompletedThisWeek: 0,
				tasksCompletedEver: 0,
			});
		}

		const incrementValue = isCompleted ? 1 : -1;
		await updateDoc(userStatsRef, {
			tasksCompletedToday: increment(incrementValue),
			tasksCompletedThisWeek: increment(incrementValue),
			tasksCompletedEver: increment(incrementValue),
		});

		// Return the increment value for local state update
		return incrementValue;
	} catch (error) {
		console.error('Error updating todo completion:', error);
		throw error;
	}
}

export async function fetchTaskCompletionCounts(userId) {
	try {
		const userStatsRef = doc(db, 'userStats', userId);
		const userStatsDoc = await getDoc(userStatsRef);

		if (!userStatsDoc.exists()) {
			return {
				tasksCompletedToday: 0,
				tasksCompletedThisWeek: 0,
				tasksCompletedEver: 0,
			};
		}

		return userStatsDoc.data();
	} catch (error) {
		console.error('Error fetching task completion counts:', error);
		throw error;
	}
}

export async function deleteTodo(todoId) {
	try {
		await deleteDoc(doc(db, 'todos', todoId));
	} catch (error) {
		console.error('Error deleting todo:', error);
		throw error;
	}
}

export async function createCheckoutSession(userId) {
	try {
		const checkoutSessionRef = await addDoc(
			collection(db, 'users', userId, 'checkout_sessions'),
			{
				price: process.env.REACT_APP_STRIPE_PRICE_ID,
				success_url: window.location.origin,
				cancel_url: window.location.origin,
			}
		);

		// Wait for the CheckoutSession to get attached by the extension
		const snap = await getDoc(checkoutSessionRef);
		const { sessionId } = snap.data();

		return { id: sessionId };
	} catch (error) {
		console.error('Error creating checkout session:', error);
		throw error;
	}
}

export async function checkSubscriptionStatus(userId) {
	try {
		const userDoc = await getDoc(doc(db, 'users', userId));
		const userData = userDoc.data();
		return userData?.subscriptionStatus === 'active';
	} catch (error) {
		console.error('Error checking subscription status:', error);
		throw error;
	}
}
