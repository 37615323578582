import React, { useState } from 'react';
import { auth } from '../firebase';
import {
	signInWithPopup,
	GoogleAuthProvider,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
} from 'firebase/auth';

function Header({ user, projects, taskCounts }) {
	const [menuOpen, setMenuOpen] = useState(false);
	const [showLoginForm, setShowLoginForm] = useState(false);
	const [showHelp, setShowHelp] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isRegistering, setIsRegistering] = useState(false);
	const [error, setError] = useState('');

	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};

	const handleGoogleLogin = async () => {
		try {
			const provider = new GoogleAuthProvider();
			await signInWithPopup(auth, provider);
			setShowLoginForm(false);
		} catch (error) {
			console.error('Error signing in with Google:', error);
			setError(error.message);
		}
	};

	const handleEmailLogin = async (e) => {
		e.preventDefault();
		try {
			if (isRegistering) {
				await createUserWithEmailAndPassword(auth, email, password);
			} else {
				await signInWithEmailAndPassword(auth, email, password);
			}
			setShowLoginForm(false);
			setEmail('');
			setPassword('');
			setError('');
		} catch (error) {
			console.error('Error with email auth:', error);
			setError(error.message);
		}
	};

	const helpContent = {
		title: 'WHY SO BRUTAL?',
		text: `Because this isn't your typical "feel-good" productivity app.

		BRUTUS doesn't let you get away with excuses. It's opinionated about how you should work. It's rigid in its approach. And it's brutal in its execution. Here's how this works:

		1. BRAIN DUMP: Dump all your tasks here. Get them out of your head and into the app. No organization, no thinking, just dump.

		2. ASSIGN TASKS: When you're ready to face reality, hit "Assign Tasks". BRUTUS will put 3 tasks in NOW and the rest in LATER. Why 3? Because you can't handle more than that.

		3. GET SHIT DONE: Here's where BRUTUS gets really brutal. You can ONLY work on the FIRST task in your NOW list. No skipping, no switching, no bullshit. You either do it or stare at it until you do.

		Why so rigid? Because you need it. You've tried the flexible approach, and how's that working out?

		BRUTUS is here to force you into actually finishing what you start, one task at a time.`,
	};

	return (
		<header className='app-header bg-black text-white'>
			<div className='header-top flex justify-between items-center'>
				<div className='header-left'>
					<h1 className='text-3xl font-bold'>
						BRUTUS
						<a
							href='#'
							onClick={(e) => {
								e.preventDefault();
								setShowHelp(true);
							}}
							className='help-text'
						>
							WHY?
						</a>
					</h1>
				</div>
				{/* <div
					className='help-icon'
					onClick={() => setShowHelp(true)}
				>
					?
				</div> */}
				<div className='header-right flex items-center'>
					{user ? (
						<div className='relative'>
							<div
								className='avatar bg-white text-black rounded-full w-10 h-10 flex items-center justify-center cursor-pointer'
								onClick={toggleMenu}
							>
								{user.email.charAt(0).toUpperCase()}
							</div>
							{menuOpen && (
								<div className='menu absolute right-0 mt-2 w-48 bg-white text-black rounded-md shadow-lg'>
									<ul>
										<li className='p-2 border-b'>
											{user.email}
										</li>
										{/* <li>
											<a href='/profile'>Profile</a>
										</li> */}
										<li
											className='p-2 hover:bg-gray-200 cursor-pointer'
											onClick={() => auth.signOut()}
										>
											Logout
										</li>
									</ul>
								</div>
							)}
						</div>
					) : (
						<>
							<button
								onClick={() => setShowLoginForm(true)}
								className='login-btn'
							>
								Login
							</button>
							{showLoginForm && (
								<div className='login-popup'>
									<div className='login-form'>
										<button
											className='close-btn'
											onClick={() =>
												setShowLoginForm(false)
											}
										>
											×
										</button>
										<h2>Login</h2>
										{error && (
											<div className='error-message'>
												{error}
											</div>
										)}
										<form onSubmit={handleEmailLogin}>
											<input
												type='email'
												placeholder='Email'
												value={email}
												onChange={(e) =>
													setEmail(e.target.value)
												}
												required
											/>
											<input
												type='password'
												placeholder='Password'
												value={password}
												onChange={(e) =>
													setPassword(e.target.value)
												}
												required
											/>
											<button type='submit'>
												{isRegistering
													? 'Register'
													: 'Login'}
											</button>
										</form>
										<button
											className='google-login-btn'
											onClick={handleGoogleLogin}
										>
											Login with Google
										</button>
										<button
											className='toggle-auth-mode'
											onClick={() =>
												setIsRegistering(!isRegistering)
											}
										>
											{isRegistering
												? 'Already have an account? Login'
												: 'Need an account? Register'}
										</button>
									</div>
								</div>
							)}
						</>
					)}
				</div>
			</div>
			{user && (
				<div className='project-controls mt-4 flex justify-between items-center'>
					<div className='task-counts flex space-x-4'>
						<span>Today: {taskCounts.tasksCompletedToday}</span>
						<span>
							This Week: {taskCounts.tasksCompletedThisWeek}
						</span>
						<span>Ever: {taskCounts.tasksCompletedEver}</span>
					</div>
				</div>
			)}
			{showHelp && (
				<>
					<div
						className='help-overlay'
						onClick={() => setShowHelp(false)}
					/>
					<div className='help-popup'>
						<button onClick={() => setShowHelp(false)}>×</button>
						<h2>{helpContent.title}</h2>
						<div style={{ whiteSpace: 'pre-line' }}>
							{helpContent.text}
						</div>
					</div>
				</>
			)}
		</header>
	);
}

export default Header;
